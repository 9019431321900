.horizontal-scroll-container {
  overflow-x: auto;
  white-space: nowrap;
  padding: 20px;
  scroll-behavior: smooth;
  /* Smooth scrolling */
  -ms-overflow-style: none;
  /* Hide scrollbar for Internet Explorer and Edge */
  scrollbar-width: none;
  /* Hide scrollbar for Firefox */
  width: 100%;

  &::-webkit-scrollbar {
    display: none;
    /* Hide scrollbar for Chrome, Safari, and Opera */
  }

  .horizontal-scroll-content {
    display: inline-flex;
    transition: transform 0.1s ease-out;
    /* Smooth transition */
    justify-content: center;
    min-width: 100%;
    padding-right: 2em;
  }
}
