.footer {
  width: 100%;
  background: #f8f9fa !important;
  align-items: center;

  .footerContainer {
    display: flex;
    flex-direction: row !important;
    width: 90%;
    padding: 3em 0;
    justify-content: space-between;

    &.smallScreen {
      flex-direction: column !important;
      align-items: center;
    }

    .footerSection {
      display: flex;
      flex-direction: column;

      .footerSectionContent {
        display: flex;
        flex-direction: column;
        padding: 1em 0;

        .footerSectionContentItem {
          cursor: pointer;
          padding: 1.2px 0px;

          &:hover {
            color: rgb(63, 113, 163);
          }
        }
      }
    }
  }

  .footerCopyRight {
    background: #232f3e;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    width: 100%;
  }
}
