.openModalButton {
  padding: 15px 30px;
  font-size: 18px;
  color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
  /* Light transparent background */
  border: 2px solid #fff;
  border-radius: 5px;
  cursor: pointer;
  transition:
    background-color 0.3s ease,
    color 0.3s ease;

  &:hover {
    background-color: rgba(255, 255, 255, 0.3);
    /* Slightly more opaque on hover */
    color: #000;
  }
}

.css-1qsxih2 {
  max-width: "100vw!important";
}
