.youtube-container {
  position: relative;
  width: 100%;
  padding-top: 56.25%;
  /* 16:9 Aspect Ratio */
  overflow: hidden;
  background: black;

  .loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 18px;
  }

  .video-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;

    &.loaded {
      opacity: 1;
    }

    .video-player {
      width: 100%;
      height: 100%;

      iframe {
        width: 100%;
        height: 100%;
        border: 0;
      }
    }
  }
}
