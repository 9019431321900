.parallax-content {
  background: "white";
  padding: 20px;
  position: "absolute";
  top: "50%";
  left: "50%";
  transform: "translate(-50%,-50%)";

  &.secondary {
    background-color: rgb(227, 224, 224);
  }

  &.homepageImg {
    padding-top: 0px;

    img {
      filter: brightness(0.6) !important;
    }
  }

  .openModalButton {
    padding: 15px 30px;
    font-size: 18px;
    color: #fff;
    background-color: rgba(255, 255, 255, 0.1);
    /* Light transparent background */
    border: 2px solid #fff;
    border-radius: 5px;
    cursor: pointer;
    transition:
      background-color 0.3s ease,
      color 0.3s ease;

    &:hover {
      background-color: rgba(255, 255, 255, 0.3);
      /* Slightly more opaque on hover */
      color: #000;
    }
  }
}
