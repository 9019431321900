.flyover {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: white !important;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  transition: transform 0.3s ease-in-out;
  transform: translateX(100%);
  max-width: 100%;
  flex-direction: column;
  justify-content: flex-start;

  &.open {
    transform: translateX(0);
  }

  .overlay {
    position: relative;
    width: 100%;
    height: 80px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    max-width: 100%;

    #headerLogoContainer {
      width: fit-content !important;
      margin: 0 !important;
    }
  }

  .flyover-content {
    width: 100%;
    border-top: 1px solid gray;
    padding: 0;
    margin: 0;
    overflow-y: scroll;
  }

  a {
    color: white;
    text-decoration: none;
  }
}
