.event-card {
  border: 1px solid #ccc;
  padding: 16px;
  margin-bottom: 8px;
  cursor: pointer;
  width: 100%;
  transition: background-color 0.3s ease;
  background: red;

  &:hover {
    background-color: #f9f9f9;
  }
}
