.service-card {
  height: 320px;
  padding: 1.7em;
  background: linear-gradient(114deg, #fff, #a0cee975);
  box-sizing: border-box;

  &:hover .card-content {
    transform: rotateY(180deg);
  }
}

.card-content {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.6s ease;
  transform-style: preserve-3d;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  box-sizing: border-box;
}

.card-front,
.card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  display: flex !important;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
}

.card-back {
  transform: rotateY(180deg);
}

.service-name {
  font-weight: bold;
  margin-bottom: 10px;
}

.service-details {
  padding: 10px;
}

.documentation-link {
  margin-top: 10px;
  color: #007bff;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}
