.accordion {
  margin: 10px 0;
  width: 100%;

  .accordion-header {
    padding: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    &:hover,
    &.open {
      background-color: #f9f9f9;
    }
  }

  .accordion-content {
    max-height: 0;
    overflow: hidden;
    transition:
      max-height 0.3s ease,
      padding 0.3s ease;

    &.open {
      max-height: 200px;
      /* Adjust based on your content */
      padding: 15px;
    }

    p {
      margin: 0;
    }
  }
}
