.team-card {
  background-color: #f1f1f1;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-right: 10px;
  padding: 20px;
  text-align: center;
  flex: 0 0 auto;
  white-space: normal;
  transition: transform 0.3s ease-out;
  /* Smooth transition */
  width: 250px;
  /* Ensures a minimum width for the cards */
  margin: 0px 12.5px !important;
}

.team-card-photo {
  width: 60%;
  border-radius: 50%;
  object-fit: cover;
}

.team-card-info {
  padding-top: 10px;
}

.team-card-name {
  font-weight: bold;
}

.team-card-role {
  font-weight: lighter;
}

.team-card-icons {
  margin-top: 10px;
}

.team-card-icons a {
  margin: 0 5px;
  color: inherit;
  text-decoration: none;
}

.team-card-icons i {
  font-size: 1.2em;
}
