.technology-card {
  height: 250px;
  perspective: 1000px;
  margin: 8px 0;
  padding: 1.5em;
  background: linear-gradient(114deg, #fff, #a0cee975);

  @media (min-width: 600px) {
    height: 270px;
  }

  @media (min-width: 960px) {
    height: 300px;
  }

  &:hover .card-content {
    transform: rotateY(180deg);
  }
}

.card-content {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.card-front,
.card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  display: flex !important;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 16px;
  box-sizing: border-box;
}

.card-back {
  transform: rotateY(180deg);
}

.technology-name {
  font-weight: bold;
  margin-bottom: 10px;
}

.technology-details {
  padding: 10px;
}

.documentation-link {
  margin-top: 10px;
  color: #007bff;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.technologyCardLink {
  font-size: small !important;
  padding-top: 1.5em;
  &:hover {
    color: rgb(63, 113, 163);
  }
}
