#headerContainer {
  width: 100%;
  height: 80px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid rgb(212, 210, 210);
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1000;
  background: white;
  opacity: 1;

  &.page-theme-dark {
    background: black;
  }

  .headerLogoContainer {
    padding-right: 0px !important;
  }

  img {
    z-index: 1000;
  }

  &.sticky {
    background: black;
    color: white;
    opacity: 0.8;
    font-weight: bold;
  }

  #navigationContainer {
    width: calc(100% - 100px);
    display: flex;
    flex-direction: row;
    justify-content: center;

    #topNavigationContainer {
      display: flex;
      flex-direction: row;
      justify-content: end;
    }
  }

  #topNavigationMenuContainer {
    display: flex;
    margin: 0px !important;
    padding: 0px 12px !important;
    width: auto;
    height: 45px;
    min-width: 120px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;

    .topNavigationMenuContent {
      &:hover,
      &.selected {
        background: #006064 !important;
        color: white;
      }

      font-weight: bold;
    }
  }

  .navigationMenuItem {
    background: whitesmoke;
    width: max-content;
    word-wrap: normal;
    position: absolute;
    top: 73px;
    left: 0px;
    padding: 0;

    &.page-theme-dark {
      background: black;
      opacity: 0.8;
    }

    .navigationMenuItemContent {
      :hover {
        color: #006064;
      }
    }

    .poppover-tip {
      position: absolute;
      top: -10px;
      /* Adjust based on the height of the triangle */
      left: 50%;
      transform: translateX(-50%);
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid #fff;

      &::before {
        content: "";
        position: absolute;
        top: -1px;
        left: -10px;
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid #ccc;
      }
    }
  }
}
