.fileUploader {
  width: 100% !important;
  max-width: auto !important;
  padding: 2em;
  box-sizing: border-box;
  &.iEwCFv {
    max-width: 100%;
    height: 200px;
    min-width: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    border: 2px dashed #2d4f6e; // Dashed border with a professional color
    border-radius: 10px; // Rounded corners
    background-color: #f9fafb; // Light gray background
    transition: all 0.3s ease; // Smooth transition on hover

    &:hover {
      border-color: #356ca5; // Darker blue on hover
      background-color: #f4f7fb;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }

    svg {
      font-size: 18px;
      color: #2d4f6e;
      font-weight: 600;
      margin-bottom: 5px;
    }

    div {
      flex-grow: initial;
      display: flex;
      flex-direction: row;
      gap: 1;
      width: 100%;
      justify-content: center;

      & > span {
        padding: 1em;
        font-size: 12px;
      }
    }
  }
}

// Floating icon animation
@keyframes float {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px);
  }
}
