.pageWrapper {
  &.page-theme-dark {
    background: black !important;
    color: white !important;
  }

  &.page-theme-light {
    background: white !important;
    color: black !important;
  }
}
